import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "src/components";
import { generateUniqueID } from "src/constants/functions";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { useToast } from "src/hooks/useToast";
import { addCounterStockist, getStockist, postPlaceOrder, uploadAttachment } from "src/services/order-api";
import PurpleLeftArrowIcon from "../../assets/purple-left-arrow.svg";
import Button from "../../components/Button";
import OrderReviewProductCard from "./OrderReviewProductCard";
import OrderUinConfirmation from "../../../../hooks/useOrderUinConfirmation";

interface Item {
  companyProducts?: {
    item_division: {
      cluster_code: {
        cluster_code: string;
        cluster_name: string;
      };
    }[];
  };
}

const OrderReviewScreen = () => {
  const { toast } = useToast();

  const { locationCode } = useParams();
  const { cartDataObj, selectedCounterData } = useSelector((state: RootState) => state.ord);
  const { idTokenDetails } = useSelector((state: RootState) => state.auth);
  const { currentPsr } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [remark, setRemark] = useState("");
  const [placingOrder, setPlacingOrder] = useState(false);
  const [stockist, setStockist] = useState<any>(null);
  const [selectedStockist, setSelectedStockist] = useState<any[]>([]);
  const [isLoading] = useState(false);
  const [orderType, setOrderType] = useState<any>("noApproval");
  const [clusterCodes, setClusterCodes] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null); // State to store the selected file
  const [stockistFile, setStockistFile] = useState<File | null>(null); // State to store the selected file
  const [uploading, setUploading] = useState(false); // State to handle loading state
  const [uploaded, setUploaded] = useState(false);
  const [districtStockist, setDistrictStockist] = useState<any[]>([]);
  const [filePath, setFilePath] = useState<{
    counter_copy_path: string | "";
    stockist_copy_path: string | "";
  }>({
    counter_copy_path: "",
    stockist_copy_path: "",
  });
  // const [location, setLocation] = useState({
  //   latitude: 0,
  //   longitude: 0,
  // });

  const dispatch = useDispatch();

  const {
    modal,
    isOpen: checkingUINOpen,
    checkUINValidation,
    isLoading: checkingUINLoading,
  } = OrderUinConfirmation({
    divisionCode: Object.values(cartDataObj).map((item: any) => item?.counter_ff_div_id?.company_division_code?.company_divisioncode),
    counterCode: selectedCounterData?.counter_location?.counter_hdr_id?.counter_code,
    counterLocationCode: selectedCounterData?.counter_location?.counter_location_id,
    onUinVerified: () => {
      handlePlaceOrder();
    },
  });

  // navigator.geolocation.getCurrentPosition(
  //   (position) => {
  //     console.log("Latitude:", position.coords.latitude);
  //     console.log("Longitude:", position.coords.longitude);
  //     setLocation({
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude,
  //     });
  //   },
  //   (error) => {
  //     console.error("Error getting location:", error);
  //   }
  // );

  const handleRemarkChange = (e: any) => {
    setRemark(e.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const handleStockistFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setStockistFile(selectedFile);
  };

  const handleUpload = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!file && !stockistFile) {
      toast({ description: "Please select a file before uploading.", variant: "destructive" });
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append("counter_copy_path", file);
    }
    if (stockistFile) {
      formData.append("stockist_copy_path", stockistFile);
    }

    setUploading(true);

    try {
      const response = await uploadAttachment(formData);

      if (response?.status !== 200) {
        throw new Error("Failed to upload file. Please try again.");
      }

      const data = response?.data?.data;
      setFilePath({
        counter_copy_path: data?.file_path?.counter_copy_path,
        stockist_copy_path: data?.file_path?.stockist_copy_path,
      });
      setUploaded(true);
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const handleStockistChange = (value: any, type: string) => {
    const valueArray = Array.isArray(value) ? value : [value];

    switch (type) {
      case "add": {
        setSelectedStockist((prev: any[]) => {
          const newSelection = valueArray.filter(
            (item: any) => !prev.some((prevItem: any) => prevItem.unique_stockist_id === item.unique_stockist_id)
          );
          return [...prev, ...newSelection];
        });

        const selectedStockistIds: string[] = [];
        const selectedStockistClusterCodes: string[] = [];
        valueArray.forEach((item: any) => {
          selectedStockistIds.push(item?.unique_stockist_id);
          selectedStockistClusterCodes.push(item?.cluster_code);
        });

        //  Disable other stockists in the same cluster
        setStockist((prev: any) =>
          prev.map((item: any) => {
            if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.unique_stockist_id)) {
              item.disabled = true;
            }
            return item;
          })
        );

        //  Disable other district stockists in the same cluster
        setDistrictStockist((prev: any) =>
          prev.map((item: any) => {
            if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.unique_stockist_id)) {
              item.disabled = true;
            }
            return item;
          })
        );

        break;
      }

      case "remove": {
        setSelectedStockist((prev: any[]) =>
          prev.filter((item) => !valueArray.some((removeItem: any) => removeItem.unique_stockist_id === item.unique_stockist_id))
        );

        const selectedStockistIds: string[] = [];
        const selectedStockistClusterCodes: string[] = [];
        valueArray.forEach((item: any) => {
          selectedStockistIds.push(item?.unique_stockist_id);
          selectedStockistClusterCodes.push(item?.cluster_code);
        });
        //  Re-enable all stockists in the same cluster
        setStockist((prev: any) =>
          prev.map((item: any) => {
            if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.unique_stockist_id)) {
              item.disabled = false;
            }
            return item;
          })
        );

        //  Re-enable all district stockists in the same cluster
        setDistrictStockist((prev: any) =>
          prev.map((item: any) => {
            if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.unique_stockist_id)) {
              item.disabled = false;
            }
            return item;
          })
        );

        break;
      }
    }
  };

  // const subtotal = Object.values(cartDataObj)?.reduce((acc: number, item: any) => acc + Number(item?.companyProducts?.ptr) * item?.cartQty, 0);
  const subtotal = Object.values(cartDataObj)?.reduce((acc: number, item: any) => {
    const price =
      item?.priceldentifier?.priceldentifier_id === 3 ? Number(item?.counter_product_slab?.[0]?.fixedprice_cp) : Number(item?.companyProducts?.ptr);

    return acc + price * (item?.cartQty || 0);
  }, 0);
  const totalItems = Object.values(cartDataObj)?.reduce((acc: number, item: any) => acc + item?.cartQty, 0);

  const getTotalDiscount = (): number => {
    let totalDiscount = 0;

    Object.values(cartDataObj)?.forEach((item: any) => {
      const ptr = Number(item?.companyProducts?.ptr || 0);
      const cartQty = Number(item?.cartQty || 0);
      const priceIdentifier = item?.priceldentifier?.priceldentifier_id;
      const slab = item?.counter_product_slab?.[0];

      if (!slab) return;

      switch (priceIdentifier) {
        case 2: // Scheme Discount
          totalDiscount += ptr * Math.floor(cartQty / (slab.schemeqty_cp || 1)) * (slab.quantity_cp || 0);
          break;

        case 3: // Fixed Price Discount
          totalDiscount += Number(slab.fixedprice_cp || 0) * cartQty;
          break;

        case 1: // Percentage Discount
        default:
          totalDiscount += (ptr * cartQty * Number(slab.discountonptr_cp || 0)) / 100;
          break;
      }
    });

    return Number(totalDiscount.toFixed(2));
  };

  const now = new Date();
  const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC +5:30
  const istTime = new Date(now.getTime() + istOffset);
  const istISOString = istTime.toISOString().slice(0, -1); // Removes the 'Z' to avoid confusion

  const handlePlaceOrder = async () => {
    if (!selectedStockist) {
      toast({ description: "Please select a stockist!", variant: "destructive" });
      return;
    }
    if (Object.keys(cartDataObj)?.length === 0) {
      toast({ description: "Please add products to the cart!", variant: "destructive" });
      return;
    }
    if (!orderType) {
      toast({ description: "Please select an order mode!", variant: "destructive" });
      return;
    }

    if ((file || stockistFile) && !uploaded) {
      toast({ description: "Please upload the file before placing the order!", variant: "destructive" });
      return;
    }

    const items: Item[] = Object.values(cartDataObj);

    // Check if all clusters have a selected stockist
    for (const item of items) {
      const cluster_ucode = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_code;
      const cluster_name = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_name;
      const stockistForCluster = selectedStockist.find((stockist: any) => stockist.cluster_code === cluster_ucode);
      if (!stockistForCluster) {
        toast({
          description: `Stockist for cluster "${cluster_name}" is not selected.`,
          variant: "destructive",
        });
        return;
      }
    }

    const groupedOrderGroups: any = {};
    Object.values(cartDataObj)?.forEach((item: any, index: number) => {
      const ordergroup_id = item?.companyProducts?.item_division[0]?.ordergroup_id?.ordergroup_id;
      const cluster_ucode = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_code;
      const cmp_countertag_id = item?.counter_ff_div_id?.cmp_countertag_id;

      let cndnRemarksCode: string = "";
      if (cmp_countertag_id) {
        cndnRemarksCode = item?.counter_ff_div_id?.cmp_countertag?.cndn_remark_code;
      } else {
        cndnRemarksCode = item?.companyProducts?.item_division[0]?.ordergroup_id?.order_cndn_remarks?.cndn_remarks_code;
      }

      const groupKey = cmp_countertag_id ? `${cmp_countertag_id}_${cluster_ucode}` : `${ordergroup_id}_${cluster_ucode}`;

      if (!groupedOrderGroups[groupKey]) {
        const stockistForCluster = selectedStockist.find((stockist) => stockist.cluster_code === cluster_ucode);

        if (stockistForCluster) {
          groupedOrderGroups[groupKey] = {
            stockist_id: stockistForCluster?.id,
            // stockist_name: stockistForCluster?.name,
            company_stockist_code: stockistForCluster?.company_stockist_code,
            medvol_stockist_code: stockistForCluster?.medvol_stockist_code,
            cluster_code: cluster_ucode,
            cluster_ucode: item?.companyProducts?.item_division[0]?.cluster_code?.Cluster_UniqueId,
            ordergroup_id: ordergroup_id,
            cmp_countertag_id: cmp_countertag_id,
            cndn_remarks_code: cndnRemarksCode,
            order_mode: orderType,
            headquarter_code: item?.counter_ff_div_id?.position_code?.emp_position_devrel[0]?.headQuarter_code,
            bill_amount: subtotal,
            discount_amount: getTotalDiscount(),
            orderProducts: [], // Initialize empty array
          };
        }
      }

      let cartQty = item?.cartQty || 0;
      if (item?.priceldentifier?.priceldentifier_id === 2) {
        const schemeqty_cp = item?.counter_product_slab?.[0]?.schemeqty_cp || 0;
        if (schemeqty_cp > 0 && cartQty % schemeqty_cp !== 0) {
          cartQty = Math.ceil(cartQty / schemeqty_cp) * schemeqty_cp;
          // cartDataObj[index] = { ...item, cartQty };
        }
      }

      // Add the product to the `orderProducts` array for the groupKey
      if (groupedOrderGroups[groupKey]) {
        groupedOrderGroups[groupKey].orderProducts.push({
          counter_product: item?.counter_product_lnk_id || "",
          quantity: cartQty || 0,
          sequence_number: index + 1,
          ucode: generateUniqueID(),
          company_division_code: item?.counter_ff_div_id?.company_division_code?.company_divisioncode || "",
          item_division_id: item?.counter_ff_div_id?.company_division_code?.company_divisioncode || "",
          product_name: item?.companyProducts?.name || "",
          item_code: item?.companyProducts?.item_code || "",
          ptr: item?.companyProducts?.ptr || 0,
        });
      }
    });

    const clusters_ordergroup_data = Object.values(groupedOrderGroups);

    const orderPayload = {
      data: {
        company_code: Number(selectedCounterData?.company_code),
        counter_code: selectedCounterData?.counter_location?.counter_hdr_id?.counter_code,
        counter_ucode: selectedCounterData?.counter_location?.counter_hdr_id?.uid,
        counter_company_location_id: selectedCounterData?.counter_company_location_id,
        created_by: idTokenDetails?.sub || "",
        created_date: istISOString,
        source: "Mobile App",
        latitude: 40.7128,
        longitude: -74.006,
        actor_name: idTokenDetails?.name || "",
        actor_code: currentPsr?.emp_position_hdr?.position_code || "",
        actor_type: idTokenDetails?.["custom:role"],
        user_ucode: idTokenDetails?.["custom:ucode"] || "",
        cash_discount: 0,
        ucode: generateUniqueID(),
        order_freetxt_remarks: remark,
        remarkby_type: "MV",
        // position_code: currentPsr?.emp_position_hdr?.position_code || "9214LUPEDPXHYJ",
        location_code: selectedCounterData?.counter_location?.location_code,
        stockist_copy_path: filePath?.stockist_copy_path,
        counter_copy_path: filePath?.counter_copy_path,
        clusters_ordergroup: clusters_ordergroup_data,
      },
      event_type: "ORDER_ADD",
    };
    const selectedStockistIds: any = [];

    orderPayload?.data?.clusters_ordergroup?.forEach((item: any) => {
      const stockist = selectedStockist?.find(
        (stockist: any) => stockist?.id === item?.stockist_id && stockist?.cluster_code === item?.cluster_code && stockist?.is_default === false
      );
      if (stockist && !selectedStockistIds.some((s: any) => s.id === stockist.id)) {
        selectedStockistIds.push(stockist);
      }
    });

    console.log("selectedStockistIds", selectedStockistIds);

    if (selectedStockistIds.length > 0) {
      selectedStockistIds.forEach(async (item: any) => {
        const payload = {
          data: {
            is_default: true,
            counter_hdr_id: selectedCounterData?.counter_location?.counter_hdr_id?.counter_id,
            counter_company_id: selectedCounterData?.counter_company_location_id,
            remarks: "12342312",
            location_id: selectedCounterData?.counter_location?.counter_location_id,
            cluster_code: item?.cluster_code,
            company_code: selectedCounterData?.company_code,
            counter_code: selectedCounterData?.counter_location?.counter_hdr_id?.counter_code,
            stockist_cluster: item?.stockist_cluster,
            u_code: generateUniqueID(),
          },
          primary_key: selectedCounterData?.counter_location?.counter_hdr_id?.counter_id,
          event_type: "COUNTER_STOCKIST_ADD",
        };

        try {
          const res = await addCounterStockist(payload);
          if (res?.status !== 200) throw new Error(res?.data?.message);
          if (res?.status === 200) {
            setPlacingOrder(true);

            try {
              const res = await postPlaceOrder(orderPayload, selectedCounterData?.company_code);
              if (res?.status !== 201) throw new Error(res?.data?.message);
              navigate("/transactions/orders/confirmation");
              dispatch(ordActions.setOrderDetails(res?.data?.data));
              setOrderType(null);
              setPlacingOrder(false);
              setSelectedStockist([]);
            } catch (error: any) {
              setPlacingOrder(false);
              console.log(error);
              toast({ description: error?.message || "Failed to place order", variant: "destructive" });
              return;
            }
          }
        } catch (error: any) {
          setPlacingOrder(false);
          console.log(error);
          toast({ description: error?.response?.data?.message || "Failed to add stockist", variant: "destructive" });
          return;
        }
      });
    } else {
      try {
        setPlacingOrder(true);
        const res = await postPlaceOrder(orderPayload, selectedCounterData?.company_code);
        if (res?.status !== 201) throw new Error(res?.data?.message);
        navigate("/transactions/orders/confirmation");
        dispatch(ordActions.setOrderDetails(res?.data?.data));
        setOrderType(null);
        setPlacingOrder(false);
        setSelectedStockist([]);
      } catch (error: any) {
        setPlacingOrder(false);
        console.log(error);
        toast({ description: error?.message || "Failed to place order", variant: "destructive" });
        return;
      } finally {
        setPlacingOrder(false);
      }
    }
  };

  const fetchStockist = async () => {
    const newClusterCodes: any[] = [];

    Object.values(cartDataObj)?.forEach((item: any) => {
      const clusterCode = item?.companyProducts?.item_division[0]?.cluster_code?.cluster_code;
      if (clusterCode && !clusterCodes.includes(clusterCode) && !newClusterCodes.includes(clusterCode)) {
        newClusterCodes.push(clusterCode);
      }
    });
    setClusterCodes(newClusterCodes);
    // filterClusterProducts();
    try {
      const res = await getStockist(selectedCounterData?.counter_company_location_id, newClusterCodes);
      if (res?.status !== 200) throw new Error(res?.data?.error);

      setStockist(res?.data?.data);
      setDistrictStockist(
        res?.data?.districtData.map((item: any) => ({ ...item, unique_stockist_id: `district-${item?.cluster_code}-${item?.stockist_id}` }))
      );

      const preSelectStockist = res?.data?.data
        ?.filter((item: any) => item?.is_default)
        .map((item: any) => ({
          name: item?.owner_name,
          id: item?.stockist_id,
          company_stockist_code: item?.company_stockist_code,
          cluster_code: item?.cluster_code,
          counter_stockist_Lnk_Id: item?.counter_stockist_Lnk_Id,
          medvol_stockist_code: item?.medvol_stockist_code,
          name_id: `${item?.cluster_name} - ${item?.owner_name}`,
          is_default: item?.is_default,
          unique_stockist_id: `${item?.cluster_code}-${item?.stockist_id}`,
        }));

      setSelectedStockist(preSelectStockist);

      const selectedStockistIds: string[] = [];
      const selectedStockistClusterCodes: string[] = [];
      preSelectStockist.forEach((item: any) => {
        selectedStockistIds.push(item?.unique_stockist_id);
        selectedStockistClusterCodes.push(item?.cluster_code);
      });

      // ✅ Disable stockists not selected
      setStockist((prev: any) => {
        return prev.map((item: any) => {
          if (selectedStockistClusterCodes.includes(item?.cluster_code) && !selectedStockistIds.includes(item?.unique_stockist_id)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
      });
    } catch (error: any) {
      console.log(error);
      toast({ description: error?.response?.data?.message || "Failed to fetch stockist", variant: "destructive" });
    }
  };

  const handleOrderTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderType(e.target.value);
  };

  useEffect(() => {
    fetchStockist();
  }, [locationCode]);

  return (
    <div>
      {checkingUINLoading && (
        <div className="inset-x-0 top-0 z-50 backdrop-blur-sm">
          <div className="flex items-center justify-center p-1 bg-gradient-to-r from-violet-600 via-purple-500 to-indigo-500">
            <div className="flex items-center gap-3">
              <div className="w-5 h-5 rounded-full border-[3px] border-white/80 border-t-transparent animate-spin" />
              <p className="text-sm font-medium text-white/90">Checking UIN...</p>
            </div>
          </div>
        </div>
      )}

      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <>
            {/* <div className="p-6 px-10 border-b border-purple-200 shadow-md bg-gradient-to-r from-purple-50 to-purple-100"> */}
            <div className="p-6 px-16 border-b shadow-md 2xl:px-16">
              <Button className="text-lg px-0 text-[#7F56D9]" onClick={() => navigate(`/transactions/orders/products/${locationCode}`)}>
                <img src={PurpleLeftArrowIcon} alt="back" className="mr-4" />
                Back to products
              </Button>
              <div className="w-full px-0">
                <h1 className="font-semibold text-[2rem]">Review Order</h1>
              </div>
            </div>
            {placingOrder ? (
              <div className="flex flex-col justify-center items-center h-[50vh]">
                <Spinner />
                <h1 className="text-[1.5rem] mt-4 font-semibold">Please Wait</h1>
                <p className="text-[1rem] text-gray-500">Your order is being placed.</p>
              </div>
            ) : (
              <div className="flex justify-center w-full gap-10 px-16 mt-0 2xl:px-16">
                <div className="mt-10 w-[70%]">
                  <div className="flex items-center justify-between mb-0">
                    <h1 className="font-semibold text-[1.4rem]">All Products</h1>
                    <p className="bg-[#F8F7FF] px-4 py-2 rounded-lg text-[#8051ff] font-medium text-[.9rem]">
                      {Object.keys(cartDataObj)?.length} Items | {totalItems} units
                    </p>
                  </div>

                  {clusterCodes?.map((item: any) => {
                    const clusterProducts = Object.values(cartDataObj)?.filter(
                      (product: any) => product?.companyProducts?.item_division[0]?.cluster_code?.cluster_code === item
                    );
                    const clusterStockist = stockist?.filter((stockist: any) => stockist?.cluster_code === item);
                    const selectedStockists = selectedStockist?.filter((stockist: any) => stockist?.cluster_code === item);

                    // Filter district stockist if no default exists
                    const hasDefault = clusterStockist?.some((s: any) => s.is_default);
                    const filteredClusterStockist = hasDefault
                      ? clusterStockist.filter((stockist: any) => stockist.is_default)
                      : districtStockist
                          ?.filter((district: any) => district?.cluster_code === item)
                          .map((district: any) => ({
                            ...district,
                            unique_stockist_id: `district-${district?.cluster_code}-${district?.stockist_id}`,
                            is_district: true,
                          }));
                    return (
                      <OrderReviewProductCard
                        key={item}
                        clusterProducts={clusterProducts}
                        clusterStockist={filteredClusterStockist}
                        selectedStockist={selectedStockists}
                        handleStockistChange={handleStockistChange}
                      />
                    );
                  })}

                  <div className="flex justify-end gap-6 mt-4 mb-20">
                    <p className="text-[1rem] text-gray-500">Order Mode :</p>
                    <div className="flex gap-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="orderType"
                          value="regular"
                          checked={orderType === "regular"}
                          onChange={handleOrderTypeChange}
                          className="text-indigo-500 form-radio"
                        />
                        <span>Regular</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="orderType"
                          value="noApproval"
                          checked={orderType === "noApproval"}
                          onChange={handleOrderTypeChange}
                          className="text-indigo-500 form-radio"
                        />
                        <span>No Approval</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="w-[25%] mt-10 bg-white">
                  {/* Remark Section */}
                  <div className="mb-6">
                    <label className="block mb-4 text-[1.4rem] font-semibold">Add remarks</label>
                    {
                      <div>
                        <div className="flex flex-col gap-2 mb-10">
                          <h1 className="font-semibold text-gray-600">Customer Upload</h1>
                          <form onSubmit={handleUpload} className="flex justify-between gap-2">
                            <label className="relative px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg cursor-pointer hover:bg-indigo-600">
                              Choose File
                              <input
                                type="file"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                onChange={handleFileChange}
                                accept="image/*,.pdf,.doc,.docx,.xlsx,.ppt,.pptx,.txt"
                              />
                            </label>
                            <div className="flex items-center gap-2">
                              <span className="py-2 ml-0 rounded-lg">{file ? file?.name : "No file chosen"}</span>
                              {file && (
                                <button type="button" onClick={() => setFile(null)} className="p-1 text-gray-500 hover:text-red-500">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                            <Button
                              type="submit"
                              disabled={uploading}
                              className="px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-600"
                            >
                              {uploading ? "Uploading..." : "Upload"}
                            </Button>
                          </form>
                        </div>
                        <div className="flex flex-col gap-2 mb-10">
                          <h1 className="font-semibold text-gray-600">Stockist Upload</h1>
                          <form onSubmit={handleUpload} className="flex justify-between gap-2">
                            <label className="relative px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg cursor-pointer hover:bg-indigo-600">
                              Choose File
                              <input
                                type="file"
                                className="absolute inset-0 opacity-0 cursor-pointer"
                                onChange={handleStockistFileChange}
                                accept="image/*,.pdf,.doc,.docx,.xlsx,.ppt,.pptx,.txt"
                              />
                            </label>
                            <div className="flex items-center gap-2">
                              <span className="py-2 ml-0 rounded-lg">{stockistFile ? stockistFile?.name : "No file chosen"}</span>
                              {stockistFile && (
                                <button type="button" onClick={() => setStockistFile(null)} className="p-1 text-gray-500 hover:text-red-500">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                            <Button
                              type="submit"
                              disabled={uploading}
                              className="invisible px-4 py-2 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-600"
                            >
                              {uploading ? "Uploading..." : "Upload"}
                            </Button>
                          </form>
                        </div>
                      </div>
                    }
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        placeholder="Type your remark here..."
                        value={remark}
                        onChange={handleRemarkChange}
                        className="flex-grow px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      />
                    </div>
                  </div>

                  {/* Pricing Summary */}
                  <div className="mb-6 space-y-3">
                    <div className="flex justify-between mb-2 text-lg">
                      <span>Subtotal</span>
                      <span className="">₹{subtotal?.toFixed(2)}</span>
                    </div>
                    {/* <div className="flex justify-between mb-2">
                      <span className="text-[#616161] font-semibold">Discount</span>
                      <span className="text-[#24a23d] font-semibold">-₹{getTotalDiscount()?.toFixed(2)}</span>
                    </div> */}
                    {/* <div className="flex justify-between mb-2">
                      <span>GST</span>
                      <span>₹22</span>
                    </div> */}
                    <div className="flex justify-between text-lg font-semibold">
                      <span>Total</span>
                      {/* <span>{(subtotal - getTotalDiscount())?.toFixed(2)}</span> */}
                      <span>₹{subtotal?.toFixed(2)}</span>
                    </div>
                  </div>

                  {/* Place Order Button */}
                  <Button onClick={checkUINValidation} className="w-full py-2 font-semibold text-white bg-indigo-500 rounded-lg hover:bg-indigo-600">
                    Place Order
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {checkingUINOpen ? modal : null}
    </div>
  );
};

export default OrderReviewScreen;
