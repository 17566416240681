import { Fragment, useEffect, useState } from "react";
import Button from "../../components/Button";
import downArrow from "src/assets/arrow-down.svg";
import moment from "moment";
import SuccessIcon from "../../assets/successCircle.svg";
import CancelCircleIcon from "../../assets/cancelCircle.svg";
import { getActionPermission, orderApproveRejectActionPermission } from "src/services/order-api";
import { useSelector } from "react-redux";
import { RootState } from "src/context/store";
import { Modal } from "src/components";
import OrderApprovalForm from "./OrderApprovalForm";
import FormSuccess from "../../container/FormSuccess";
import OrderHierarchySidebar from "../OrderHierarchy/OrderHierarchySidebar";
import { useToast } from "src/hooks/useToast";
import OrderStatusTooltip from "./OrderStatusTooltip";
import { Transition } from "@headlessui/react";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import Comments from "./Comments";
import { useEventStatus } from "src/hooks/useEventStatus";
import { ordActions } from "src/context/order-slice";

const OrderedProduct = ({ products, index }: { products: any; index: number }) => {
  const [isExpanded, setIsExpanded] = useState(index === 0);
  // const [isExpanded, setIsExpanded] = useState(false);
  const order_id_int = products?.order_id_int;
  const [actionList, setActionList] = useState<string[]>([]);
  const { currentPsr } = useSelector((state: RootState) => state.auth);
  const { selectedCounterData } = useSelector((state: RootState) => state.ord);
  const [isHierarchyOpen, setIsHierarchyOpen] = useState(false);
  const [hierarchyOrderId, setHierarchyOrderId] = useState<number>(0);
  const [remarksOrderId, setRemarksOrderId] = useState<number>();
  const [isCheckingPermission, setIsCheckingPermission] = useState(false);
  const [isFiltersShown, setIsFiltersShown] = useState<boolean>(false);
  const [orderUid, setOrderUid] = useState<string>();

  const { withEventCheck } = useEventStatus({
    retries: 10,
    retryDelay: 1000,
    setBlocked: ordActions.setEventBlocked,
    setPending: ordActions.setEventPending,
  });

  const { toast } = useToast();

  const totalQuantity = products?.orderProducts?.reduce((acc: number, curr: any) => acc + curr?.quantity, 0);
  const totalBilledPrice = products?.orderProducts?.reduce((acc: number, curr: any) => acc + Number(curr?.billed_price), 0);

  const ref = useOutsideClick(() => {
    setIsFiltersShown(false);
    setIsHierarchyOpen(false);
  });

  const [modalStates, setModalStates] = useState({
    approveCounter: false,
    rejectCounter: false,
    errorForm: false,
    order_id: 0,
  });

  const closeHierarchyModal = () => {
    setIsHierarchyOpen(false);
    // setHierarchyOrderId(NaN);
    // dispatch(ordActions.setApprovalHierarchy([]));
  };

  const closeModal = (modalName: string) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalName]: false,
    }));
  };

  const resetForm = () => {
    setModalStates({
      approveCounter: false,
      rejectCounter: false,
      errorForm: false,
      order_id: 0,
    });
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const checkPermission = async (action: string, position_code: string, order_id: number, callback: any) => {
    if (isCheckingPermission) return;
    setIsCheckingPermission(true);
    try {
      const response = await orderApproveRejectActionPermission(position_code, action, order_id, selectedCounterData?.company_code);
      setIsCheckingPermission(false);
      // if (response.status !== 200) {
      //   throw new Error();
      // }
      if (response?.data?.data?.allowed === true) {
        callback();
      } else {
        setModalStates((prev) => {
          return { ...prev, errorForm: true };
        });
      }
    } catch (error: any) {
      console.log(error);
      setIsCheckingPermission(false);
      toast({ description: "Something went wrong", variant: "destructive" });
      setModalStates((prev) => {
        return { ...prev, errorForm: true };
      });
    }
  };

  const fetchActionPermission = async (streamId?: string) => {
    if (isCheckingPermission) return;
    setIsCheckingPermission(true);

    setActionList([]);

    if (currentPsr?.emp_position_hdr?.position_code) {
      try {
        const response = await withEventCheck(
          () => getActionPermission(currentPsr?.emp_position_hdr?.position_code, order_id_int, selectedCounterData?.company_code),
          streamId
        );

        setActionList(response?.data?.data?.operation_list);
      } catch (error: any) {
        console.log(error);
        resetForm();
      } finally {
        setIsCheckingPermission(false);
      }
    }
  };

  useEffect(() => {
    if (index === 0) {
      fetchActionPermission();
    }
    if (isExpanded) {
      fetchActionPermission();
    }
  }, [index, isExpanded]);

  // useEffect(() => {
  //   fetchActionPermission();
  // }, [order_id_int]);

  return (
    <Fragment>
      <Transition
        as={Fragment}
        show={isFiltersShown}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <Comments ref={ref} order_id={Number(remarksOrderId) as number} uid={orderUid as string} />
        </div>
      </Transition>
      <Transition
        as={Fragment}
        show={isHierarchyOpen}
        enter="transition transition ease-in-out duration-200 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition  ease-in-out duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed right-0 inset-0 top-0 z-[500]">
          <OrderHierarchySidebar ref={ref} onClose={closeHierarchyModal} order_id={hierarchyOrderId} />
        </div>
      </Transition>

      <div>
        <Modal isOpen={modalStates.approveCounter} isHeader={true} onCloseModal={() => closeModal("approveCounter")} title="Approve Order">
          <OrderApprovalForm
            type={true}
            order_id={order_id_int}
            position_code={currentPsr?.emp_position_hdr?.position_code}
            closeModal={closeModal}
            onSuccess={(streamId: string) => {
              fetchActionPermission(streamId);
            }}
          />
        </Modal>
        {modalStates.rejectCounter && (
          <Modal
            isOpen={modalStates.rejectCounter}
            isHeader={true}
            title="Reject Product"
            onCloseModal={() => {
              closeModal("rejectCounter");
              resetForm();
            }}
          >
            <OrderApprovalForm
              onSuccess={(streamId: string) => {
                fetchActionPermission(streamId);
              }}
              order_id={order_id_int}
              position_code={currentPsr?.emp_position_hdr?.position_code}
              type={false}
              closeModal={closeModal}
            />
          </Modal>
        )}
        <div className="w-full mt-6 mx-auto shadow-sm border border-[#D0D0D0] rounded-xl p-6 bg-white" key={index}>
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <h3 className="font-semibold text-[1.2rem] flex items-center gap-2">
                {products?.order_code}{" "}
                <span className="border border-[#E4E4E6] rounded-lg px-2 py-1 text-[.8rem]">CD : {products?.order_cash_discount_perc}%</span>
              </h3>
              <OrderStatusTooltip
                orderStatusCode={products?.orderStatusHdr?.order_status_id}
                orderStatusText={products?.orderStatusHdr?.order_status_desc}
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 mt-0 mr-4">
                <p className="text-[.9rem]  border border-[#E4E4E6] rounded-lg px-2 py-1 font-semibold">
                  Total Billed Price: <span className="text-[#6551ff]">{totalBilledPrice.toFixed(2)}</span>
                </p>
                <p className="text-[.9rem] font-semibold">
                  Total Qty. <span className="text-[#6551ff]">{totalQuantity}</span>
                </p>
              </div>
              <Button className="w-10 h-10 bg-[#F1F1F1] border border-1-black" onClick={handleToggle}>
                <img className={`transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`} src={downArrow} alt="down-arrow" />
              </Button>
            </div>
          </div>
          <div
            className={`overflow-hidden mt-4 transition-all duration-500 ease-in-out ${
              isExpanded ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"
            }`}
            style={{ transitionProperty: "max-height, opacity" }}
          >
            <div className="border-t border-[#d0d0d0]"></div>
            {products?.orderProducts?.map((product: any) => (
              <div className="mt-6">
                <div key={product?.order_product_id}>
                  <div className="flex items-center gap-4">
                    <p className="font-semibold text-[1rem]">{product?.product_name}</p>

                    {/* <p className="text-[.9rem] font-semibold border border-[#E4E4E6] rounded-lg px-2 py-1">CD: {product?.discount_on_ptr}%</p> */}
                    {/* <p className="text-[.9rem] border border-[#E4E4E6] rounded-lg px-2 py-1">
                    Discount Type: {product?.price_identifier === 1 ? "Discount on PTR" : product?.price_identifier === 2 ? "Scheme" : "Fixed Price"}
                  </p>
                  <p className=" text-[.9rem]">
                    Order Qty. {product?.quantity} + {product?.free_quantity}
                  </p> */}
                    {product?.price_identifier === 1 && (
                      <>
                        <p className="text-[.9rem] border border-[#E4E4E6] rounded-lg px-2 py-1">Discount Type: Discount on PTR</p>
                        <p className="text-[.9rem]">Discount: {product?.discount_on_ptr}%</p>
                        <p className="text-[.9rem]">Order Qty. {product?.quantity}</p>
                      </>
                    )}
                    {product?.price_identifier === 2 && (
                      <>
                        <p className="text-[.9rem] border border-[#E4E4E6] rounded-lg px-2 py-1">Discount Type: Scheme</p>
                        <p className="text-[.9rem]">
                          Scheme Qty: {product?.scheme_quantity} + {product?.free_quantity}
                        </p>

                        <p className="text-[.9rem]">Order Qty. {product?.quantity}</p>
                      </>
                    )}
                    {product?.price_identifier === 3 && (
                      <>
                        <p className="text-[.9rem] border border-[#E4E4E6] rounded-lg px-2 py-1">Discount Type: Fixed Price</p>
                        <p className="text-[.9rem]">Fixed Price: {product?.fixed_price}</p>
                        <p className="text-[.9rem]">Order Qty. {product?.quantity}</p>
                      </>
                    )}
                  </div>
                  <div className="overflow-x-scroll">
                    <table className="min-w-full mt-2 border">
                      <thead className="w-full">
                        <tr>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Order Date</th>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Company</th>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Division</th>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">MRP</th>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">PTR</th>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Billed Price</th>
                          <th className="px-4 py-1 text-[.8rem] font-normal text-left text-[#B5B5B5] border border-[#d0d0d0]">Item Discount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-sm">
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{moment(product?.created_date).format("Do MMMM YYYY")}</td>
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{products?.company_name}</td>
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{products?.division_name}</td>
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{product?.mrp}</td>
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{product?.ptr}</td>
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{product?.billed_price}</td>
                          <td className="px-4 py-1  text-[.8rem] border border-[#d0d0d0]">{product?.item_discount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                </div>
              </div>
            ))}
          </div>
          <footer className="flex items-center justify-end">
            {/* <Button
            isLoading={isCheckingPermission}
            className="border bg-[#f1f1f1] border-[#d5d5d5] rounded-lg min-w-[40px] min-h-[40px] relative"
            onClick={fetchActionPermission}
            newAnimation={true}
          >
            Check Permission
          </Button> */}
            {/* if is checking permission then show food animation and text */}

            {isCheckingPermission && (
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent animate-[animate-gradient_2s_ease-in-out_infinite_alternate]">
                  Checking permissions...
                </span>
              </div>
            )}

            <div className="flex gap-2">
              {actionList?.includes("approve") && (
                <Button
                  className=" border border-[#d5d5d5] rounded-lg min-w-[40px] min-h-[40px]"
                  onClick={() => {
                    checkPermission("approve", currentPsr?.emp_position_hdr?.position_code, order_id_int, () => {
                      setModalStates({ ...modalStates, approveCounter: true });
                    });
                  }}
                  isLoading={isCheckingPermission}
                >
                  <img src={SuccessIcon} alt="counter_details_check" className="w-full h-full mr-2" /> Appprove
                </Button>
              )}
              {actionList?.includes("reject") && (
                <Button
                  className=" border border-[#d5d5d5] rounded-lg min-w-[40px] min-h-[40px]"
                  onClick={() => {
                    checkPermission("reject", currentPsr?.emp_position_hdr?.position_code, order_id_int, () => {
                      setModalStates({ ...modalStates, rejectCounter: true });
                    });
                  }}
                  isLoading={isCheckingPermission}
                >
                  <img src={CancelCircleIcon} alt="counter_details_cancel" className="w-full h-full mr-2" /> Reject
                </Button>
              )}
              <Button
                className="border bg-[#f1f1f1] border-[#d5d5d5] rounded-lg min-w-[40px] min-h-[40px]"
                onClick={() => {
                  // setHierarchyOrderId(order_id_int);
                  setRemarksOrderId(order_id_int);
                  setOrderUid(products?.uid);
                  setIsFiltersShown(true);
                }}
              >
                View Remarks
              </Button>
              <Button
                className="border bg-[#f1f1f1] border-[#d5d5d5] rounded-lg min-w-[40px] min-h-[40px]"
                onClick={() => {
                  setHierarchyOrderId(order_id_int);
                  setIsHierarchyOpen(true);
                }}
              >
                View Hierarchy
              </Button>
            </div>
          </footer>
        </div>
        <FormSuccess
          showForm={modalStates.errorForm}
          onContinue={() => resetForm()}
          variant="error"
          popupMessage={{
            description: "You don't have permission to perform this action",
            title: "Access Denied!",
          }}
        />
      </div>
    </Fragment>
  );
};

export default OrderedProduct;
