import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "src/components";
import { ordActions } from "src/context/order-slice";
import { RootState } from "src/context/store";
import { usePagination } from "src/hooks/usePagination";
import { getCounterByEmployee } from "src/services/order-api";
import Table from "../../components/Table";
import Loader from "../../components/Loader";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("counter_code", {
    cell: (info) => info.getValue(),
    header: () => "Counter Code",
    enableSorting: true,
  }),
  columnHelper.accessor("counter_name", {
    cell: (info) => info.getValue(),
    header: () => "Counter Name",
    enableSorting: true,
  }),
  columnHelper.accessor("location_name", {
    cell: (info) => info.getValue(),
    header: () => "Location Name",
    enableSorting: true,
  }),

  columnHelper.accessor("dr_ph_lnk_code", {
    cell: (info) => info.getValue(),
    header: () => "Link Type Code",
    enableSorting: true,
  }),
  columnHelper.accessor("company_name", {
    cell: (info) => info.getValue(),
    header: () => "Company",
    enableSorting: true,
  }),
  columnHelper.accessor("division_name", {
    cell: (info) => info.getValue(),
    header: () => "Division",
    enableSorting: true,
  }),
];

const OrdersHomeScreen = () => {
  const [loading, setLoading] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const { counterData } = useSelector((state: RootState) => state.ord || []);
  const { pagination, onPaginationChange, pageIndex, pageSize, resetPagination } = usePagination();
  const [search, setSearch] = useState("");
  const [isRetrieve, setIsRetrieve] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchOrders = async () => {
    try {
      setLoading(true);
      let query = "?";
      if (search) {
        query += `&search=${search.trim()}`;
      }
      query += `&limit=${pageSize}&page=${pageIndex}`;
      query = query.replace("?&", "?");
      query = query.replace("&&", "&");

      const response = await getCounterByEmployee(query);

      setTotalRowCount(response?.data?.totalCount);
      dispatch(ordActions.setCounterData(response?.data?.data));
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const rowClickHandler = async (row: any) => {
    const query = `?counter_company_location_id=${row?.original?.countercompanylnk_id}&stockist=true`;
    window.sessionStorage.setItem("counterOrderId", row?.original?.counter_code);
    window.sessionStorage.removeItem("order_id_int");
    try {
      setIsLoading(true);
      const response = await getCounterByEmployee(query);
      dispatch(ordActions.setSelectedCounterData(response?.data?.data?.[0]));
      window.sessionStorage.setItem("counterCompanyLocationId", response?.data?.data?.[0]?.counter_company_location_id);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
    navigate(`/transactions/orders/details/${row?.original?.counter_code}/0`);
    dispatch(ordActions.setSelectedCounter(row?.original));
  };

  const handleSearch = (searchQuery: string) => {
    setSearch(searchQuery);
    resetPagination();
  };

  let timerId: any;
  useEffect(() => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      if (isRetrieve) {
        resetPagination();
        fetchOrders();
      }
    }, 300);

    return () => clearTimeout(timerId);
  }, [search]);

  let pageCount = Math.ceil(totalRowCount / (pageSize ?? 1));

  useEffect(() => {
    fetchOrders();
  }, [pageIndex]);

  if (isLoading) {
    return <Loader />;
  }

  if (!isRetrieve) {
    return (
      <Fragment>
        <div className="flex justify-end float-right gap-2">
          <Input
            // debounceTime={500}
            placeholder="Search by counter"
            className="w-full rounded-xl"
            showIcon={false}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                setIsRetrieve(true);
                fetchOrders();
              }
            }}
          />
          <Button
            onClick={() => {
              setIsRetrieve(true);
              fetchOrders();
            }}
            loaderSize="small"
            className="h-auto py-2 mt-2 border"
          >
            Retrieve
          </Button>
        </div>
        <div className="pt-40 mt-2 text-center text-gray-500">No data loaded. Click Retrieve to fetch data.</div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <div className="flex justify-end float-right gap-2">
          <Input
            debounceTime={500}
            placeholder="Search by counter"
            className="w-full rounded-xl"
            showIcon={false}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button onClick={fetchOrders} loaderSize="small" className="h-auto py-2 mt-2 border">
            Retrieve
          </Button>
        </div>

        <Table
          columns={columns}
          title="Counters"
          subtitle="View and manage counters"
          data={counterData ?? []}
          onRowClick={rowClickHandler}
          isLoading={loading}
          isGlobalSearch={true}
          showSearch={false}
          manualPagination={true}
          pageCount={pageCount}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          onGlobalSearch={handleSearch}
        />
      </div>
    </Fragment>
  );
};

export default OrdersHomeScreen;
